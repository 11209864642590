<template>
<div>
    <div v-for="(menuList,index) in menuLists" :key="index" class="menuListBox text-left mb-3 pl-3" @mouseover="index==1?isHover=true:isHover" @mouseout="index==1?isHover=false:isHover" @click="goToOtherPage(index)">
        <b-icon v-if="(menuList.icon).length > 0" class="mr-2" :icon="menuList.icon" font-scale="1" v-bind:rotate="menuList.rotate"></b-icon>
        <img id="imgFb" v-if="(menuList.src).length > 0&&isHover" class="mr-2" src="./../../assets/facebook.png" width="17px" />
        <img id="imgFb" v-if="(menuList.src).length > 0&&!isHover" class="mr-2" src="./../../assets/facebook2.png" width="17px" />
        <span>{{menuList.menuName}}</span>
    </div>
</div>
</template>

<script>
import router from '../../router';
import {
    mapGetters
} from 'vuex'

export default {
    name: 'SidebarMenu',
    props: ['menuLists'],
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    data() {
        return {
            isHover: false
        }
    },
    mounted() {
        this.checkMode()
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    methods: {
        goToOtherPage(index) {
            if (index == 0) {
                router.push("/Control/manageNovel")
            } else if (index == 1) {
                window.open("https://www.facebook.com/NovelRealm", '_blank');
            } else if (index == 2) {
                window.open("https://www.facebook.com/groups/novelrealm", '_blank');
            } else if (index == 3) {
                window.open("https://www.facebook.com/groups/novelrealm/permalink/635543156908422", '_blank');
            }
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('menuListBox').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('menuListBox').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
}
</script>

<style scoped>
.menuListBox {
    font-size: 16px;
    background-color: #ffffff;
    border: none;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.darkmode {
    background-color: #35363A;
}

.menuListBox:hover {
    color: #ffffff;
    cursor: pointer;
    background-color: #EE806B;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

b-icon:hover {
    color: white;
}
</style>
